import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Contacts = Loader(lazy(() => import('src/content/dashboards/Contacts')));
const Agencies = Loader(lazy(() => import('src/content/dashboards/Agencies')));
const Partners = Loader(lazy(() => import('src/content/dashboards/Partners')));
const Partner = Loader(lazy(() => import('src/content/dashboards/Partner')));
const Facturacion = Loader(lazy(() => import('src/content/dashboards/Facturacion')));
const Administracion = Loader(lazy(() => import('src/content/dashboards/Administracion')));
const Soporte = Loader(lazy(() => import('src/content/dashboards/Soporte')));
const Experiences = Loader(lazy(() => import('src/content/dashboards/Experiences')));
const Reports = Loader(lazy(() => import('src/content/dashboards/Analiticas')));
const Franchises = Loader(lazy(() => import('src/content/dashboards/Franchises')));
const Marketing = Loader(lazy(() => import('src/content/dashboards/Marketing')));



// const PanelGifty = Loader(lazy(() => import('src/content/dashboards/gifty/Panel')));

const user = JSON.parse(localStorage.getItem('user'));
const path = (user?.rol === 'ACCOUNT_MANAGER_ROLE' || user?.rol === 'EXTERNAL_PARTNER') ? 'partners' : ('MARKETING_ROLE' ? 'marketing' : 'contacts');

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to={path} replace />
  },
  {
    path: 'contacts',
    element: <Contacts/>
  },
  {
    path: 'agencies',
    element: <Agencies/>
  },
  {
    path: 'partners',
    element: <Partners/>
  },
  {
    path: 'partners/:id',
    element: <Partner/>
  },
  {
    path: 'facturacion',
    element: <Facturacion/>
  },
  {
    path: 'administracion',
    element: <Administracion/>
  },
  {
    path: 'support',
    element: <Soporte/>
  },
  {
    path: 'experiences',
    element: <Experiences/>
  },
  {
    path: 'reports',
    element: <Reports/>
  },
  {
    path: 'franchises',
    element: <Franchises/>
  },
  {
    path: 'marketing',
    element: <Marketing/>
  }
];

export default dashboardsRoutes;

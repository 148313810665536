import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import GroupsIcon from '@mui/icons-material/Groups';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SavingsIcon from '@mui/icons-material/Savings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import CampaignIcon from '@mui/icons-material/Campaign';


export const getItems = (user) => {
  const menuItems = [
    {
      heading: 'Contactos',
      roles: ['ADMIN_ROLE', 'SALES_ROLE'],
      items: [
        {
          heading: 'Contactos',
          icon: PermContactCalendarIcon,
          link: 'dashboards/contacts',
        },
      ]
    },
    {
      heading: 'Meeting Makers',
      roles: ['ADMIN_ROLE', 'SALES_ROLE'],
      items: [
        {
          heading: 'Meeting Makers',
          icon: GroupsIcon,
          link: 'dashboards/agencies',
        },
      ]
    },
    {
      heading: 'Partners',
      roles: ['ADMIN_ROLE', 'ACCOUNT_MANAGER_ROLE', 'SALES_ROLE', 'EXTERNAL_PARTNER'],
      items: [
        {
          heading: 'Partners',
          icon: StorefrontIcon,
          link: 'dashboards/partners',
        },
      ]
    },
    {
      heading: 'Estadísticas',
      roles: ['ADMIN_ROLE'],
      items: [
        {
          heading: 'Estadísticas',
          icon: QueryStatsIcon,
          link: 'dashboards/reports',
        },
      ]
    },
    {
      heading: 'Facturacion',
      roles: ['ADMIN_ROLE'],
      items: [
        {
          heading: 'Facturacion',
          icon: SavingsIcon,
          link: 'dashboards/facturacion',
        },
      ]
    },
    {
      heading: 'Administracion',
      roles: ['ADMIN_ROLE'],
      items: [
        {
          heading: 'Administracion',
          icon: SupervisorAccountIcon,
          link: 'dashboards/administracion',
        },
      ]
    },
    {
      heading: 'Soporte',
      roles: ['ADMIN_ROLE'],
      items: [
        {
          heading: 'Soporte',
          icon: SupportAgentIcon,
          link: 'dashboards/support',
        },
      ]
    },
    {
      heading: 'Marketing',
      roles: ['ADMIN_ROLE', 'SALES_ROLE'],
      items: [
        {
          heading: 'Experiencias',
          icon: ReceiptIcon,
          link: 'dashboards/experiences',
        },
      ]
    },
    {
      heading: 'Franquicias',
      roles: ['ADMIN_ROLE', 'SALES_ROLE'],
      items: [
        {
          heading: 'Franquicias',
          icon: HolidayVillageIcon,
          link: 'dashboards/franchises',
        },
      ],
    },
    {
      heading: 'Marketing',
      roles: ['ADMIN_ROLE', 'MARKETING_ROLE'],
      items: [
        {
          heading: 'Marketing',
          icon: CampaignIcon,
          link: 'dashboards/marketing',
        },
      ],
    }

  ];

  //export default filter by roles
  const filteredMenuItems = menuItems.filter(item => {
    return item.roles.includes(user.rol);
  });
  return filteredMenuItems;
}
